import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
})

// Enable tabbable tab-nav tabs by activating it

//tab nav normal

const triggerTabList = [].slice.call(document.querySelectorAll('#item-tab li:first-child .nav-link'))
triggerTabList.forEach(function (triggerEl) {
  const tabTrigger = new bootstrap.Tab(triggerEl)

  triggerEl.addEventListener('click', function (event) {
    event.preventDefault()
    tabTrigger.show()
  })
})

$('.tab-content .tab-pane:first').addClass('show active')
$('#item-tab li .nav-link:first').addClass('active')

//tab nav company

const triggerTabListCompany = [].slice.call(document.querySelectorAll('#item-tab-company li:first-child .nav-link'))
triggerTabListCompany.forEach(function (triggerEl) {
  const tabTrigger = new bootstrap.Tab(triggerEl)

  triggerEl.addEventListener('click', function (event) {
    event.preventDefault()
    tabTrigger.show()
  })
})

$('.tab-content-company .tab-pane:first').addClass('show active')
$('#item-tab-company li .nav-link:first').addClass('active')

$('a[href*="#"]')
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
      &&
      location.hostname == this.hostname
    ) {
      var target = $(this.hash)
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
      if (target.length) {
        event.preventDefault()
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000, function() {
          var $target = $(target)
          $target.focus()
          if ($target.is(":focus")) {
            return false
          } else {
            $target.attr('tabindex','-1')
            $target.focus()
          }
        })
      }
    }
  })
